import React from "react";
import styled, { keyframes } from "styled-components";

// Create a keyframe animation for the spinner
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Styled Component for the full-page loader
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark background with opacity */
  z-index: 9999; /* Ensures loader appears above all content */
`;

// Styled Component for the spinning circle
const Spinner = styled.div`
  border: 8px solid rgba(255, 255, 255, 0.3); /* Light color with transparency */
  border-top: 8px solid #fff; /* White color for the spinner's top */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite; /* Spins the spinner */
`;

// Loader Component
const Loader = () => {
  return (
    <LoaderContainer>
      <Spinner />
    </LoaderContainer>
  );
};

export default Loader;
