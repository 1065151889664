import { message } from "antd";
import S3 from "react-aws-s3";
import { getEnvValue } from "./helper";

function generateRandomString(length = 10) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}

const config = {
  bucketName: getEnvValue("S3_BUCKET_NAME"),
  dirName: "media" /* optional */,
  region: "ap-south-1",
  accessKeyId: getEnvValue("S3_ACCESS_KEY_ID"),
  secretAccessKey: getEnvValue("S3_ACCESS_KEY_SECRET"),
};

const ReactS3Client = new S3(config);
/*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

const S3Upload = (file, cb) => {
  const uuid = generateRandomString();
  const newFileName = `${uuid}${+new Date()}`;
  ReactS3Client.uploadFile(file, newFileName)
    .then((data) => {
      if (cb) {
        cb(data);
      }
    })
    .catch((err) => {
      message.error("Failed to upload data. Please try again");
      console.error(err);
    });
};

export const AsyncS3Upload = async (file) => {
  const uuid = generateRandomString();
  const newFileName = `${uuid}${+new Date()}`;
  return ReactS3Client.uploadFile(file, newFileName);
};

export default S3Upload;
