// src/store.js (or wherever you have your store configuration)

import { configureStore } from "@reduxjs/toolkit"; // Import configureStore from Redux Toolkit
import rootReducer from "./reducers/rootReducer"; // Import your rootReducer

export default function configureAppStore(initialState = {}) {
  const store = configureStore({
    reducer: rootReducer, // Pass your root reducer
    preloadedState: initialState, // Set the initial state if needed
  });
  return store;
}
