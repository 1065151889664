import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLoaderData } from "react-router-dom";
import { SET_USER_DATA } from "./actions/common";
import Loader from "./Loader";

const NonAuthRoute = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const dispatch = useDispatch();
  const user = useLoaderData();

  useEffect(() => {
    const userData = localStorage.getItem("user");
    const token = localStorage.getItem("authToken") || "";

    // For setting the updated user data
    if (!userData || !token) {
      setIsAuthenticated(false);
      localStorage.removeItem("user");
      localStorage.removeItem("authToken");
      dispatch({
        type: SET_USER_DATA,
        payload: { user: user ?? null },
      });
    } else {
      setIsAuthenticated(true);
    }
  }, [user, dispatch]);

  if (isAuthenticated === null) {
    return <Loader />;
  }
  return !isAuthenticated ? element : <Navigate to="/dashboard" />;
};

export default NonAuthRoute;
