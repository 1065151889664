/* eslint-disable no-nested-ternary */
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Rating from "react-rating";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { axiosAuth } from "../../api";
import { API_ENDPOINTS } from "../../api/apiEndpoints";
import greyStar from "../../assets/grey-star.png";
import yellowStar from "../../assets/yellow-star.png";
import {
    CenteredContainer,
    CenteredDiv,
    CourseImage,
    FeatureList,
    FeatureListInnerUl,
    FeatureListItem,
    FeatureListItemInnerUlLI,
    OverlayDiv,
    PlanCard,
    PlanCardContent,
    PlanCardHeader,
    PriceText,
    Spacer,
} from "../../common/components";
import HeadingUnderline from "../../common/components/HeadingUnderline";

export const CouponCode = styled.div`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border: ${({ theme }) => `5px solid ${theme.borders.light}`};
  width: 400px;
  margin: 0 auto 35px auto;
  font-weight: bold;
  height: 125px;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    width: 350px;
  }
`;

const Dashboard = () => {
  const [allCourses, setAllCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getAllAuthCourses = async () => {
    try {
      setLoading(true);
      const res = await axiosAuth.get(API_ENDPOINTS.ALL_AUTHENTICATED_COURSES);
      if (res?.data?.status && res?.data?.data) {
        setAllCourses(res?.data?.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllAuthCourses();
  }, []);

  return (
    <Container>
      <Spacer />
      <HeadingUnderline title="All Courses" />
      <Spacer />
      <Row>
        {loading ? (
          <Col span={24}>
            <CenteredDiv>
              <LoadingOutlined
                style={{ fontSize: 30, color: "#1A9C5B" }}
                spin
              />
            </CenteredDiv>
          </Col>
        ) : allCourses?.length ? (
          allCourses.map((course, index) => {
            return (
              <Col
                key={course._id}
                xs={24}
                sm={12}
                md={12}
                lg={8}
                className="plan-card"
              >
                <PlanCard
                  onClick={() => {
                    if (course?.isPurchased) {
                      navigate(`/courses/${course.slug}`);
                    }
                  }}
                  isLocked={course.is_locked}
                >
                  {course.is_locked && <OverlayDiv>Locked</OverlayDiv>}
                  <PlanCardHeader>
                    {course?.course_name ?? "Course"}
                  </PlanCardHeader>
                  <PlanCardContent>
                    <CourseImage src={course?.course_image ?? ""} />
                    <Spacer />
                    <CenteredContainer>
                      <FeatureList>
                        <FeatureListItem>
                          Course Details:
                          <FeatureListInnerUl>
                            <FeatureListItemInnerUlLI>
                              {course?.course_description || "Description"}
                            </FeatureListItemInnerUlLI>
                          </FeatureListInnerUl>
                        </FeatureListItem>
                      </FeatureList>
                      <FeatureList>
                        <FeatureList>
                          <FeatureListItem>
                            Lectures:&nbsp;
                            {course?.course_lessons}
                            &nbsp;|&nbsp;Duration:&nbsp;
                            {course?.course_duration}
                            <FeatureListInnerUl>
                              <FeatureListItemInnerUlLI>
                                Validity:&nbsp;
                                {course?.course_validity}
                              </FeatureListItemInnerUlLI>
                            </FeatureListInnerUl>
                          </FeatureListItem>
                        </FeatureList>
                      </FeatureList>
                    </CenteredContainer>
                    {course?.isPurchased ? (
                      <PriceText>&nbsp;</PriceText>
                    ) : index === 1 ? (
                      <PriceDiv>
                        <NewPriceText>
                          &#8377;
                          {course?.course_amount}
                        </NewPriceText>
                      </PriceDiv>
                    ) : (
                      <PriceDiv>
                        <StrikedText>
                          &#8377;
                          {course?.base_amount}
                        </StrikedText>{" "}
                        <NewPriceText>
                          &#8377;
                          {course?.course_amount}
                        </NewPriceText>
                      </PriceDiv>
                    )}
                    {course?.rating?.rating ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <StarBox>
                          <RatingsCircle>
                            {course?.rating?.rating}
                          </RatingsCircle>
                          <Rating
                            readonly
                            start={0}
                            stop={5}
                            step={1}
                            initialRating={course?.rating?.rating}
                            emptySymbol={
                              <img
                                alt="empty-star"
                                src={greyStar}
                                style={{ width: "20px" }}
                              />
                            }
                            fullSymbol={
                              <img
                                alt="filled-star"
                                src={yellowStar}
                                style={{ width: "20px" }}
                              />
                            }
                          />
                          &nbsp;
                          <div style={{ fontSize: "10px", paddingTop: "5px" }}>
                            (
                            <strong>
                              {course?.rating?.usersCount}
                              &nbsp;Ratings
                            </strong>
                            )
                          </div>
                        </StarBox>
                      </div>
                    ) : (
                      <div style={{ height: "30px", width: "5px" }} />
                    )}
                    <CenteredContainer>
                      {course?.isPurchased ? (
                        <Button
                          onClick={() => navigate(`/courses/${course.slug}`)}
                          className="get-started-btn"
                          size="large"
                        >
                          Show Lectures
                        </Button>
                      ) : (
                        <Button
                          onClick={() => undefined}
                          className="get-started-btn"
                          size="large"
                        >
                          Purchase course
                        </Button>
                      )}
                    </CenteredContainer>
                  </PlanCardContent>
                </PlanCard>
              </Col>
            );
          })
        ) : (
          <Col span={24}>
            <h4 style={{ textAlign: "center" }}>No course found</h4>
          </Col>
        )}
      </Row>
      <Spacer />
      <Spacer />
    </Container>
  );
};

export default Dashboard;

export const StarBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: white;
  padding: 5px 20px 10px 20px;
  position: relative;
  border-radius: 18px;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const RatingsCircle = styled.div`
  background: #1A9C5B;
  position: absolute;
  left: -30px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  top: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: black;
`;

export const StrikedText = styled(PriceText)`
  text-decoration: line-through;
  text-decoration-line: line-through;
  text-decoration-color: red;
  margin-right: 5px;
`;

export const NewPriceText = styled(PriceText)`
  font-size: 18px;
`;

export const PriceDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
