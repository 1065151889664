/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Col, Row } from "antd";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { WhatsAppWidget } from "react-whatsapp-widget";
import { isMobile, isMobileOnly } from "react-device-detect";
import moment from "moment";
import logo2 from "../../assets/logo.svg";
import "react-whatsapp-widget/dist/index.css";
import { FooterContainer } from ".";

const LogoSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  flex: 1;
  @media (max-width: 768px) {
    margin-bottom: 15px;
    height: initial;
  }
`;

const LogoImage = styled.img`
  width: 250px;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

const SiteListSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  flex: 1;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 15px;
    height: initial;
  }
`;

const SiteLinkModified = styled(SiteListSection)`
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

const FollowUsHeading = styled.h6`
  color: #1a9c5b;
`;

const SocialIconsList = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  li {
    float: left;
    color: #1a9c5b;
    list-style-type: none;
    padding: 0px 10px;
  }
  li:not(:last-child) {
    border-right: 1px solid #1a9c5b;
  }
  li:hover {
    cursor: pointer;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #c4c4c4;
  margin-bottom: 25px;
`;

const CopyrightText = styled.p`
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-align: center;
  width: 100%;
  margin-bottom: ${!isMobile ? "25px" : "85px"};
`;

const SocialAParent = styled.a``;

const whatsAPPIconPages = [
  "/",
  "/about-us",
  "/privacy-policy",
  "/terms-and-conditions",
  "/faqs",
  "/contact-us",
  "/disclaimer",
  "/refund-policy",
];
const WebinarFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authtokenExist = localStorage.getItem("authToken");
  const [scrollPosition, setScrollPosition] = useState(null);

  const handleLinkClick = useCallback((path) => {
    navigate(path);
  }, []);

  const listenToScroll = useCallback(() => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    setScrollPosition(scrolled);
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const showWhatsApp = useMemo(() => {
    if (whatsAPPIconPages.includes(location.pathname)) {
      if (location.pathname == "/") {
        if (isMobile) {
          return scrollPosition > 0.1;
        }
        return scrollPosition > 0.15;
      }
      return true;
    }
    return false;
  }, [scrollPosition, location]);

  return (
    <FooterContainer>
      <div
        className={showWhatsApp && !authtokenExist ? "showing" : "not-showing"}
      >
        <div className="whatsapp-button">
          <WhatsAppWidget
            companyName="Aditya Singh Fitness"
            phoneNumber="+916267990054"
          />
        </div>
      </div>
    </FooterContainer>
  );
};

export default WebinarFooter;
