import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import "./App.scss";
import RootLayout from "./RootLayout";
import LandingPage from "./routes/LandingPage";
import configureStore from "./store";

//Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { axiosAuth } from "./api";
import { API_ENDPOINTS } from "./api/apiEndpoints";
import AuthRoute from "./AuthRoute";
import Cart from "./common/components/cart";
import { authLoader } from "./loaders";
import NonAuthRoute from "./NonAuthRoute";
import FramerMotionProvider from "./providers/framer-motion-provider";
import About from "./routes/about";
import AdminDashboard from "./routes/adminDashboard";
import ContactUs from "./routes/contactUs";
import Dashboard from "./routes/dashboard";
import Disclaimer from "./routes/disclaimer";
import FAQS from "./routes/faqs";
import Lessons from "./routes/lessonsByCourse";
import Login from "./routes/login";
import MyProfile from "./routes/myProfile";
import Notifications from "./routes/notifications";
import PrivacyPolicy from "./routes/privacyPolicy";
import RefundPolicy from "./routes/refundPolicy";
import ShippingDelivery from "./routes/shippingDelivery";
import Checkout from "./routes/signup";
import TermsConditions from "./routes/termsConditions";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    loader: async () => {
      const res = await axiosAuth.get(
        API_ENDPOINTS.GET_ALL_UNAUTHENTICATED_COURSES
      );
      return res?.data?.data ?? [];
    },
    Component: RootLayout,
    children: [
      {
        index: true,
        element: <NonAuthRoute element={<LandingPage />} />,
      },
      {
        path: "login",
        element: <NonAuthRoute element={<Login />} />,
      },
      {
        path: "checkout",
        element: <NonAuthRoute element={<Checkout />} />,
      },
      {
        path: "cart",
        element: <NonAuthRoute element={<Cart />} />,
      },
      {
        path: "about-us",
        Component: About,
      },
      {
        path: "privacy-policy",
        Component: PrivacyPolicy,
      },
      {
        path: "terms-and-conditions",
        Component: TermsConditions,
      },
      {
        path: "faqs",
        Component: FAQS,
      },
      {
        path: "contact-us",
        Component: ContactUs,
      },
      {
        path: "disclaimer",
        Component: Disclaimer,
      },
      {
        path: "refund-policy",
        Component: RefundPolicy,
      },
      {
        path: "shipping-and-delivery",
        Component: ShippingDelivery,
      },
      {
        path: "/account-created",
        loader: authLoader,
        element: <AuthRoute element={<div>Account created</div>} />,
      },
      {
        path: "/dashboard",
        loader: authLoader,
        element: <AuthRoute element={<Dashboard />} />,
      },
      {
        path: "/courses/:slug",
        loader: authLoader,
        element: <AuthRoute element={<Lessons />} />,
      },
      {
        path: "/admin-dashboard",
        loader: authLoader,
        element: <AuthRoute element={<AdminDashboard />} />,
      },
      {
        path: "/notifications",
        loader: authLoader,
        element: <AuthRoute element={<Notifications />} />,
      },
      {
        path: "/profile",
        loader: authLoader,
        element: <AuthRoute element={<MyProfile />} />,
      },
    ],
  },
]);

function App() {
  return (
    <FramerMotionProvider>
      <Provider store={configureStore()}>
        <RecoilRoot>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            newestOnTop
            pauseOnHover
          />
          <div className="margin-app">
            <RouterProvider router={router} />
          </div>
        </RecoilRoot>
      </Provider>
    </FramerMotionProvider>
  );
}

export default App;
