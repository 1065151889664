import * as m from "framer-motion/m";
import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
const ActionButton = ({ smallButton }) => {
  const history = useNavigate();
  const handleClick = () => history("/webinar-registration");

  if (smallButton) {
    return (
      <m.button
        className="gradient-btn"
        onClick={handleClick}
        style={{
          padding: "15px 30px",
          border: "none",
          color: "white",
          fontSize: "16px",
          cursor: "pointer",
          borderRadius: "8px",
        }}
        whileHover={{
          scale: 1.1,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
          transition: { duration: 0.3 },
        }}
        whileTap={{ scale: 0.95 }}
      >
        Take Action For Your Family NOW!
      </m.button>
    );
  }

  return (
    <button
      onClick={handleClick}
      type="button"
      className={`button-85 ${isMobile ? "button-85-mobile" : ""}`}
    >
      Take Action For Your Family NOW!
    </button>
  );
};

export default ActionButton;
