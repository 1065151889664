import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  removeNewFromLesson,
  setCurrentVideo,
  setLessons,
} from "../../actions/lessons";
import { axiosAuth } from "../../api";
import { API_ENDPOINTS } from "../../api/apiEndpoints";
import { CenteredDiv } from "../../common/components";
import RefundConfirmationModal from "../../common/components/RefundConfirmationModal";
import LessonComments from "./LessonComments";
import LessonPlayer from "./LessonPlayer";
import LessonsList from "./LessonsList";

const Lessons = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const allLessons = useSelector((state) => state.lessons.allLessons);
  const user = useSelector((state) => state.common.user);

  useEffect(
    () => () => {
      dispatch(setCurrentVideo(null));
    },
    [dispatch]
  );

  useEffect(() => {
    const isCoursePurchased = user?.purchasedCourses?.find(
      (x) => x.slug === params.slug && !x.isRefunded
    );
    if (user?.purchasedCourses?.length && !isCoursePurchased && user) {
      navigate("/dashboard");
    }
  }, [user, navigate, params.slug]);

  const getLessons = async () => {
    try {
      setLoading(true);
      const res = await axiosAuth.get(
        `${API_ENDPOINTS.LESSONS_BY_SLUG}/${params.slug}`
      );
      const allSections = res?.data?.data?.sections ?? [];
      const lastWatchedLesson = res?.data?.data?.lastWatchedLesson ?? null;
      const finalData = allSections.map((section) => {
        const lessons = section.lessons.map((item) => ({ ...item, section }));
        const isNew = section.lessons.find(
          (lesson) => lesson.isNewLesson === true
        );
        return {
          ...section,
          isNew: !!isNew,
          lessons,
        };
      });
      dispatch(setLessons(finalData));
      if (lastWatchedLesson) {
        dispatch(
          setCurrentVideo({
            ...lastWatchedLesson,
            section: allSections.find(
              (x) => x.ID === lastWatchedLesson?.sectionId
            ),
          })
        );
      } else {
        const firstVideo = finalData[0]?.lessons[0];
        if (firstVideo?.isNewLesson) {
          axiosAuth.post(API_ENDPOINTS.UPDATE_WATCH_TIME, {
            courseSlug: params.slug,
            isCompleted: firstVideo?.isCompleted === true,
            lessonId: firstVideo?._id,
          });
        }
        dispatch(
          removeNewFromLesson(0, 0, {
            ...firstVideo,
            isNewLesson: false,
          })
        );
        dispatch(setCurrentVideo({ ...firstVideo, isNewLesson: false }));
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        "Failed to load lessons. Please contact admin to get the issue resolved"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    getLessons();
  }, [params]);

  if (loading && allLessons?.length === 0) {
    return (
      <CenteredDiv>
        <LoadingOutlined style={{ fontSize: 30, color: "#1A9C5B" }} spin />
      </CenteredDiv>
    );
  }
  return (
    <>
      <RefundConfirmationModal />
      <Row style={{ background: "rgb(247, 249, 250)" }}>
        <Col
          style={{ maxHeight: "93vh", backgroundColor: "black" }}
          span={isMobileOnly || isTablet ? 0 : 6}
        >
          <LessonsList allLessons={allLessons} />
        </Col>
        <Col span={isMobileOnly || isTablet ? 24 : 18}>
          <LessonPlayer />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <LessonComments />
        </Col>
      </Row>
    </>
  );
};

export default Lessons;
