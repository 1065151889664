import * as m from "framer-motion/m";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import logo from "../../../assets/logo.svg";
import ActionButton from "../ActionButton";

const AboutMe = () => {
  // Track whether the div has crossed the threshold
  const [hasCrossed, setHasCrossed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 350 && !hasCrossed) {
        setHasCrossed(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log({ hasCrossed });
  return (
    <ColoredContainer>
      <Container>
        <Box>
          <m.div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            initial={{ x: "-200%" }} // Start position off the left
            animate={{ x: hasCrossed ? 0 : "-200%" }} // End position at normal position
            transition={{ duration: 2 }} // Duration of the animation
          >
            <Heading>So, Who am I?</Heading>
            <SubHeading>
              Hi, I'm Aditya Singh, a certified personal trainer, dietician, and
              competitive bodybuilder. My mission is to help men combat the
              decline in testosterone levels and sperm count caused by Endocrine
              Disrupting Chemicals. With testosterone levels dropping by an
              average of 1% each year over the past 50 years, it's crucial to
              take action.
              <br />
              <br />
              In my class, you'll find practical advice and strategies to
              optimize your hormonal health and overall well-being naturally.
              Let's work together to reclaim your vitality and achieve your
              fitness goals.
            </SubHeading>
            <ActionButton smallButton />
          </m.div>
          <ImageContainer>
            <m.img
              width={"100%"}
              src={logo}
              alt="logo-image"
              initial={{ x: "200%" }} // Start position off the right
              animate={{ x: hasCrossed ? 0 : "200%" }} // End position at normal position
              transition={{ duration: 2 }} // Duration of the animation
            />
          </ImageContainer>
        </Box>
      </Container>
    </ColoredContainer>
  );
};

export default AboutMe;

export const Heading = styled.div`
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const SubHeading = styled(Heading)`
  font-size: 20px;
  font-weight: normal;
`;

const ColoredContainer = styled.div`
  background-color: white;
  padding: ${isMobile ? "15px 0 30px 0px" : "30px 0 30px 0"};
  overflow-x: hidden;
`;

const Box = styled.div`
  display: flex;
  flex-direction: ${isMobile ? "column-reverse" : "row"};
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
