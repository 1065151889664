import { notification } from "antd";
import axios from "axios";

export const getUserToken = () => localStorage.getItem("authToken");
const excludeUrls = [
  "fitness/auth/logout",
  "fitness/auth/signup",
  "fitness/user/isUserExists",
  "fitness/auth/login",
];

const getEnvValue = (name) => {
  const value = process.env[`REACT_APP_${name}`];
  return value || "";
};

export const axiosAuth = axios.create({
  baseURL: getEnvValue("API_BASE_URL"),
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

axiosAuth.interceptors.request.use((req) => {
  if (getUserToken()) {
    req.headers.authorization = `Bearer ${getUserToken()}`;
  }
  return req;
});

axiosAuth.interceptors.response.use((response) => {
  if (response?.config?.url === "/fitness/auth/logout") {
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
  }
  console.log({ response: JSON.stringify(response) });
  if (
    (response?.data?.code === 403 || response?.data?.code === 401) &&
    response?.data?.status === false &&
    !excludeUrls.includes(response?.config?.url)
  ) {
    notification.error({
      message: "Session Expiry!!",
      description: "Session Expired. Please login in again to continue",
    });
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    setTimeout(() => {
      window.location.href = "/";
    }, 500);
    return;
  }
  return response;
});
