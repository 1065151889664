import { ShoppingCartOutlined } from "@ant-design/icons";
import { Steps } from "antd";
import React, { useMemo } from "react";
import { RiSecurePaymentFill, RiUserAddLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const { Step } = Steps;

const Stepper = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const headerParam = params.get("header");
  const codeParam = params.get("coupon-code");
  const currentStep = useMemo(() => {
    if (pathname === "/cart") {
      return 0;
    }

    if (pathname === "/checkout") {
      return 1;
    }

    return 0;
  }, [pathname]);

  const handleCartClick = () => {
    let queryParams = {};
    if (headerParam) {
      queryParams = { ...queryParams, header: false };
    }
    if (codeParam) {
      queryParams = { ...queryParams, "coupon-code": codeParam };
    }
    const queryString = new URLSearchParams(queryParams).toString();
    navigate(`/cart${queryString ? `?${queryString}` : ""}`);
  };

  return (
    <CustomStepper current={currentStep} type="navigation" size="small">
      <Step
        onClick={handleCartClick}
        status="finish"
        title="Cart"
        style={{ cursor: "pointer" }}
        icon={<ShoppingCartOutlined />}
      />
      <Step status="finish" title="Checkout" icon={<RiUserAddLine />} />
      <Step status="process" title="Payment" icon={<RiSecurePaymentFill />} />
    </CustomStepper>
  );
};

export default Stepper;

const CustomStepper = styled(Steps)`
  .ant-steps-item-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .ant-steps-icon {
    font-size: 25px !important;
    color: #1A9C5B !important;
  }

  .ant-steps-item-content {
    margin-top: 5px;
  }

  .ant-steps-item::after {
    top: 60%;
    left: 94%;
  }

  .ant-steps-item::before {
    background-color: #1A9C5B !important;
  }
`;
