import * as m from "framer-motion/m";
import React from "react";
import { Container } from "react-bootstrap";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { Spacer } from "../common/components";
import AboutMe from "../common/components/AboutMe";
import ActionButton from "../common/components/ActionButton";
import Subjects from "../common/components/Subjects";
import { theme } from "../utils/theme";

const LandingPage = () => (
  <div style={{ background: "black" }}>
    <MainBox>
      <Container>
        <MainContainer>
          {isMobile ? (
            <>
              <div
                className={`react-video-player-custom item-center ${
                  isTablet ? "video-margin" : ""
                }`}
              >
                <ReactPlayer
                  url="https://vimeo.com/670118315"
                  controls
                  width={isMobileOnly ? "350px" : "480px"}
                  height={isMobileOnly ? "197px" : "270px"}
                  pip
                  loop
                  playsinline
                />
              </div>
              <Spacer />
              <InfoBox>
                <BlackInfoAnalyticsContainerMobile>
                  REVIVE YOUR TESTOSTERONE NATURALLY
                </BlackInfoAnalyticsContainerMobile>
                <SubTextContainerMobile>
                  Remove chemicals from your life that are making you <br />
                  SICK. WEAK. INFERTILE. DEPRESSED
                </SubTextContainerMobile>
              </InfoBox>
            </>
          ) : (
            <>
              <div>
                <InfoVideoContainer>
                  <InfoBox>
                    <Badge>Proven Strategies</Badge>
                    <BlackInfoAnalyticsContainer>
                      Hidden Toxins Are Wrecking Your Health & Hormones—Without
                      You Knowing It!
                    </BlackInfoAnalyticsContainer>
                    <SubTextContainer>
                      Endocrine Disrupting Chemicals (EDCs) in everyday products
                      are silently weakening your body, harming fertility, and
                      affecting mental health. Learn how to identify and
                      eliminate them to protect yourself and your family.
                    </SubTextContainer>
                  </InfoBox>
                  <VideoBox>
                    <div className="react-video-player-custom">
                      <ReactPlayer
                        url="https://www.youtube.com/watch?v=EngW7tLk6R8"
                        controls
                        width={isMobileOnly ? "350px" : "480px"}
                        height={isMobileOnly ? "197px" : "270px"}
                        pip
                        loop
                        playsinline
                      />
                    </div>
                    {/* <StyledImg src={bannerMain} /> */}
                  </VideoBox>
                </InfoVideoContainer>
                <m.div
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                  initial={{ y: "300%" }} // Start position off the bottom of the viewport
                  animate={{ y: 0 }} // End position at normal position
                  transition={{ duration: 2 }} // Duration of the animation
                  viewport={{ once: true, amount: 1 }} // Animation triggers when 100% of the div is in view
                >
                  <ActionButton smallButton />
                </m.div>
              </div>
            </>
          )}
        </MainContainer>
      </Container>
    </MainBox>
    <AboutMe />
    <Subjects />
  </div>
);

export default LandingPage;

const Badge = styled.div`
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    opacity: 1;
    transform-style: preserve-3d;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    letter-spacing: -.02em;
    background-color: #ffffff05;
    background-image: radial-gradient(circle, #ffffff0a, #fff0);
    border-radius: 99px;
    align-items: center;
    margin-bottom: 16px;
    padding: 4px 12px 4px 10px;
    font-size: 14px;
    line-height: 1.8;
    display: flex;
    box-shadow: inset 0 1px 1px #ffffff14, inset 0 2px 12px #ffffff0a;
    color: white;
    font-weight: bold;
    width: 150px;
}`;

const MainBox = styled.div`
  background-image: url("https://cdn.prod.website-files.com/66cf89fb5f30f36106c415e8/67780a6a97defccb4e25f5c0_2677809a782374b96018d0455_black-orchid%201.png"),
    linear-gradient(192deg, #65ffe5, #0000 70%);
  background-position: 0 0, 0 0;
  background-size: 150px, auto;
  height: calc(100vh - 90px);
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoldHeadingBlackText = styled.span`
  color: white;
  font-size: ${isMobile ? "25px" : "35px"};
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${isMobile ? "30px" : "0px"};
`;

const SubHeadingWhiteText = styled.span`
  font-size: 20px;
  color: white;
  text-align: center;
`;

const InfoVideoContainer = styled.div`
  display: flex;
  min-height: 300px;
  padding: ${isMobile ? "10px 0" : "10px 40px"};
  justify-content: center;
  align-items: center;
  margin-top: 0;
`;

export const InfoBox = styled.div`
  flex: 1;
  background: transparent;
  min-height: 100%;
  border-radius: 5px;
  padding: ${isMobile ? "5px 0" : "0 10px"};
`;

export const InfoAnalyticsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const InfoAnalyticsBoxContainer = styled.div`
  padding: 0 10px;
  flex: 1;
`;

export const InfoAnalyticsBox = styled.div`
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  flex: 1;
  min-height: 80px;
  margin-bottom: 10px;
  padding: ${isMobile ? "10px" : "0 10px"};
`;

const VideoBox = styled.div`
  flex: 1;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 80px;
  justify-content: space-evenly;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: 10px;
`;

export const MainNumber = styled.span`
  font-size: 25px;
  font-weight: bold;
`;

export const MainNumberYellow = styled(MainNumber)`
  color: ${theme.backgrounds.main};
`;

const BlackInfoAnalyticsContainer = styled(InfoAnalyticsContainer)`
  border-radius: 15px;
  padding: 5px 0;
  margin: 5px;
  flex-direction: column;
  font-size: 30px;
  color: white;
  font-weight: bold;
`;

const BlackInfoAnalyticsContainerMobile = styled(BlackInfoAnalyticsContainer)`
  font-size: 30px;
  text-align: center;
`;

const SubTextContainer = styled(BlackInfoAnalyticsContainer)`
  font-size: 20px;
  font-weight: normal;
`;

const SubTextContainerMobile = styled(SubTextContainer)`
  text-align: center;
`;

export const Text = styled.span`
  font-size: 15px;
  font-weight: bold;
`;

const EmptyDiv = styled.div`
  height: 150px;
  width: 1px;
`;
