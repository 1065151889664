import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setUserData, toggleRefundConfirmationModal } from '../../../actions/common';
import { removeNewFromLesson, setCurrentVideo } from '../../../actions/lessons';
import { axiosAuth } from '../../../api';
import { API_ENDPOINTS } from '../../../api/apiEndpoints';

const RefundConfirmationModal = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const showRefundOverModal = useSelector(
    (state) => state.common.showRefundOverConfirmationModal,
  );
  const allLessons = useSelector(
    (state) => state.lessons.allLessons,
  );
  const [loading, setLoading] = useState(false);

  const lastFreeLesson = useMemo(() => {
    let lessonData = null;
    allLessons.forEach((data, sectionIndex) => data?.lessons?.forEach((lessonInner, lessonIndex) => {
      if (lessonInner?.is_locked === 'no') {
        lessonData = {
          sectionIndex,
          index: lessonIndex,
          lesson: lessonInner,
        };
      }
    }));
    return lessonData;
  }, [allLessons]);

  const handleSubmitClick = () => {
    setLoading(true);
    axiosAuth
      .post(API_ENDPOINTS.MODIFY_REFUND_PERIOD, {
        slug: params?.slug,
      })
      .then((res) => {
        if (res?.data?.status) {
          const data = {
            user: res?.data?.data,
            token: localStorage.getItem('authToken'),
          };
          toast(
            'Congrats, You have unlocked the entire course successfully!!',
            {
              type: toast.TYPE.SUCCESS,
            },
          );
          dispatch(toggleRefundConfirmationModal(false));
          dispatch(setUserData(data));
          localStorage.setItem('user', JSON.stringify({ ...JSON.parse(localStorage.getItem('user') ?? '{}'), purchasedCourses: res?.data?.data?.purchasedCourses }));
        } else {
          toast(
            'Failed to unlock course. Please contact admin for further support',
            {
              type: toast.TYPE.ERROR,
            },
          );
        }
        setLoading(false);
      });
  };

  const handleClose = useCallback(() => {
    const { sectionIndex, index, lesson } = lastFreeLesson;
    dispatch(toggleRefundConfirmationModal(false));
    axiosAuth.post(API_ENDPOINTS.UPDATE_WATCH_TIME, {
      courseSlug: params.slug,
      isCompleted: lesson?.isCompleted === true,
      lessonId: lesson?._id,
    });
    dispatch(setCurrentVideo({ ...lesson, isNewLesson: false }));
    dispatch(
      removeNewFromLesson(sectionIndex, index, {
        ...lesson,
        isNewLesson: false,
      }),
    );
  }, [lastFreeLesson]);

  return (
    <Modal
      className="exit-intent-popup"
      visible={showRefundOverModal}
      closable={false}
      maskClosable={false}
    >
      <div style={{ padding: '0px 10px', position: 'relative' }}>
        <CloseCircleOutlined
          className="close-icon"
          onClick={handleClose}
          style={{ color: 'black', right: '-15px', top: '-25px' }}
        />
        <h4 className="text-center mt-10 leaving-already">
          <span style={{ borderBottom: '5px solid #1A9C5B' }}>
            Unlock Course
          </span>
        </h4>
        <h5 className="text-center mt-25 mb-25">
          <strong>
            This lesson is locked. Do you want to unlock the
            entire course?
          </strong>
        </h5>
        <Row justify="center">
          <Button
            onClick={handleSubmitClick}
            loading={loading}
            disabled={loading}
            className="signUpBtn"
            size="large"
          >
            Yes
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

export default RefundConfirmationModal;
