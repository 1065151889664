import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { axiosAuth } from '../../api';
import { API_ENDPOINTS } from '../../api/apiEndpoints';
import LessonQA from './LessonQA';
import LessonResources from './LessonResources';

const { TabPane } = Tabs;

const LessonComments = () => {
  const currentLesson = useSelector((state) => state.lessons.currentLesson);
  const user = useSelector((state) => state.common.user);
  const [activeKey, setActiveKey] = useState('1');
  const [showResources, setShowResources] = useState(true);
  const [resourceCount, setResourcesCount] = useState(null);

  useEffect(() => {
    if (activeKey !== 1) {
      setActiveKey('1');
    }
    if (currentLesson?._id) {
      axiosAuth
        .get(
          `${API_ENDPOINTS.GET_RESOURCES_BY_LESSON_ID}/${currentLesson?._id}`,
        )
        .then((res) => {
          if (res?.data?.status && res?.data?.data?.length) {
            setResourcesCount(res?.data?.data?.length);
            setShowResources(true);
          } else {
            setShowResources(false);
            setResourcesCount(res?.data?.data?.length);
          }
        });
    }
  }, [currentLesson]);

  if (!currentLesson?._id) {
    return <></>;
  }

  return (
    <CourseQAParentContainer>
      <StyledTabs activeKey={activeKey} onChange={(tab) => setActiveKey(tab)}>
        <TabPane tab="Q&A" key="1">
          <LessonQA activeKey={activeKey} />
        </TabPane>
        <TabPane
          disabled={!(showResources || user?.userType === 'admin')}
          tab={resourceCount ? `Resources (${resourceCount})` : 'No Resources'}
          key="2"
        >
          <LessonResources
            setShowResources={setShowResources}
            setResourcesCount={setResourcesCount}
            activeKey={activeKey}
          />
        </TabPane>
      </StyledTabs>
    </CourseQAParentContainer>
  );
};

export default LessonComments;

const CourseQAParentContainer = styled.div`
  padding: 0 15px;
  min-height: 200px;
  width: 100%;
  background-color: #f8f9fa;
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    font-size: 20px;
    font-weight: bold;
    &:hover {
      color: #1A9C5B !important;
    }
  }
  .ant-tabs-tab-active {
    div {
      color: #1A9C5B !important;
    }
  }
  .ant-tabs-ink-bar {
    background: #1A9C5B !important;
  }
  .ant-tabs-tab-disabled {
    &:hover {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
`;
