import {
  Button,
  Col,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useOutlet, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosAuth } from "../../api";
import { API_ENDPOINTS } from "../../api/apiEndpoints";
import { Spacer } from "../../common/components";
import { FREE_COURSE } from "../../constant";
import { getEnvValue } from "../../utils/helper";
import "./dashboard.scss";

const COURSE_PLAN_DETAILS = {};
export const AnalyticsBox = ({ color, count, text, onClick }) => (
  <div
    style={{ borderBottom: `3px solid ${color}` }}
    className="analytics-box box-shadow-effect"
    onClick={onClick || null}
  >
    <div className="analytics-details-container">
      <span className="analytics-details-heading">{text}</span>
      <span className="analytics-details-count">
        <strong>{count}</strong>
      </span>
    </div>
  </div>
);

const AdminDashboard = () => {
  const data = useOutlet();
  const data2 = useOutletContext();
  console.log({ data, data2 });
  const [coursesLoading, setCoursesLoading] = useState(false);
  const [lessonSyncing, setLessonSyncing] = useState(false);
  const [allCourses, setAllCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [ratingSelectedCourse, setRatingSelectedCourse] = useState("");
  const [pendingQA, setReviewQA] = useState(0);
  const [validity, setValidity] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [savingCouponConfig, setSavingCouponConfig] = useState(false);
  const [username, setName] = useState("");
  const [useremail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [txnId, setTxnId] = useState("");
  const [contactNo, setContact] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [ratings, setUsersRating] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [savingRatingConfig, setSavingRatingConfig] = useState(false);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [fbEmail, setFbEmail] = useState(null);
  const [fbContact, setFbContact] = useState(null);
  const [course1, setCourse1] = useState(false);
  const [course2, setCourse2] = useState(false);
  const [email, setEmailAssign] = useState("");
  const [assignLoading, toggleAssignLoading] = useState(false);

  const navigate = useNavigate();
  const { Option } = Select;

  // const getUsers = () => {
  //   axiosAuth.get(API_ENDPOINTS.ALL_USERS).then((res) => {
  //     if (res?.data?.data?.length) {
  //       setTotalUsers(res.data.data);
  //       const finalData = res?.data?.data?.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
  //       setTotalNormalUsers(
  //         finalData.filter((item) => item.userType == 'normal'),
  //       );
  //       setTotalAdminUsers(
  //         finalData.filter((item) => item.userType == 'admin'),
  //       );
  //       setTotalActiveUsers(finalData.filter((item) => item.active));
  //       setTotalDeActiveUsers(finalData.filter((item) => !item.active));
  //     }
  //   });
  // };

  const getAnalytics = () => {
    axiosAuth.get(API_ENDPOINTS.ADMIN_ANALYTICS).then((res) => {
      setAnalyticsData(res?.data?.data ?? null);
    });
  };

  const getAllCourses = () => {
    axiosAuth.get(API_ENDPOINTS.GET_ALL_UNAUTHENTICATED_COURSES).then((res) => {
      if (res?.data?.status && res?.data?.data) {
        setAllCourses(res?.data?.data);
      }
    });
  };

  const getAllPendingQA = () => {
    axiosAuth.get(API_ENDPOINTS.GET_ALL_PENDING_QAS).then((res) => {
      if (res?.data?.data && res?.data?.status) {
        setReviewQA(res?.data?.data?.length);
      }
    });
  };

  const getCouponCode = () => {
    axiosAuth.get(API_ENDPOINTS.GET_COUPON_CONFIG).then((res) => {
      if (res?.data?.data && res?.data?.status) {
        setValidity(res?.data?.data[0]?.validity);
        setDiscount(res?.data?.data[0]?.discount);
      }
    });
  };

  const getRatings = (slug) => {
    axiosAuth.get(`${API_ENDPOINTS.GET_RATING_CONFIG}/${slug}`).then((res) => {
      if (res?.data?.data?.length && res?.data?.status) {
        const [finalData] = res?.data?.data;
        setUsersRating(finalData?.rating);
        setUsersCount(finalData?.usersCount);
      } else {
        setUsersRating(0);
        setUsersCount(0);
      }
    });
  };

  useEffect(() => {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {};
    if (user?.userType !== "admin") {
      navigate("/");
      return;
    }
    getAllCourses();
    getAllPendingQA();
    getCouponCode();
    getAnalytics();
  }, []);

  const openPage = (path) => {
    navigate(path);
  };

  const handleSyncCourses = () => {
    setCoursesLoading(true);
    axiosAuth.post(API_ENDPOINTS.SYNC_COURSES).then((res) => {
      notification.success({
        message: "Courses Synced!!",
      });
      setCoursesLoading(false);
    });
  };

  const handleCouponConfigSave = () => {
    setSavingCouponConfig(true);
    axiosAuth
      .post(`${API_ENDPOINTS.CREATE_UPDATE_COUPON_CONFIG}`, {
        discount,
        validity,
      })
      .then((res) => {
        if (res?.data?.data) {
          setValidity(res?.data?.data?.validity);
          setDiscount(res?.data?.data?.discount);
        }
        notification.success({
          message: "Config Saved!!",
        });
        setSavingCouponConfig(false);
      })
      .catch(() => {
        setSavingCouponConfig(false);
      });
  };

  const handleRatingConfigSave = () => {
    setSavingRatingConfig(true);
    axiosAuth
      .post(`${API_ENDPOINTS.CREATE_UPDATE_RATINGS_CONFIG}`, {
        courseSlug: ratingSelectedCourse,
        rating: ratings,
        usersCount,
      })
      .then((res) => {
        if (res?.data?.data) {
          setUsersRating(res?.data?.data?.rating);
          setUsersCount(res?.data?.data?.usersCount);
        }
        notification.success({
          message: "Config Saved!!",
        });
        setSavingRatingConfig(false);
      })
      .catch(() => {
        setSavingRatingConfig(false);
      });
  };

  const handleLessonCourses = () => {
    if (
      window.confirm(
        "Are you sure want to sync the lessons for selected course?"
      )
    ) {
      setLessonSyncing(true);
      axiosAuth
        .get(`${API_ENDPOINTS.SYNC_LESSONS_BY_COURSE}/${selectedCourse}`)
        .then((res) => {
          notification.success({
            message: "Lessons Synced!!",
          });
          setLessonSyncing(false);
        });
    }
  };

  const handleCreateClick = () => {
    if (useremail && username && password && contactNo && txnId) {
      setLoading(true);
      const signUpPayload = {
        name: username,
        email: useremail,
        contactNo,
        password,
        userType: "normal",
        profileImg: "",
        active: true,
      };

      axiosAuth
        .post(API_ENDPOINTS.SIGN_UP, signUpPayload)
        .then((res) => {
          if (
            res.data &&
            res.data.status &&
            res.data.data &&
            res.data.data.user &&
            res.data.data.token
          ) {
            const purchasedCourseDetails = [
              {
                amount: COURSE_PLAN_DETAILS.course_amount,
                courseId: COURSE_PLAN_DETAILS._id,
                startDate: moment().format("YYYY/MM/DD"),
                isRefundPeriodOver: false,
                course_name: COURSE_PLAN_DETAILS.course_name,
                slug: COURSE_PLAN_DETAILS.slug,
                txnId,
                endDate: moment().add(12, "months"),
                userType: "course",
              },
            ];
            axios
              .post(
                `${getEnvValue("API_BASE_URL")}${
                  API_ENDPOINTS.PURCHASE_COURSE
                }`,
                purchasedCourseDetails,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${res.data.data.token}`,
                  },
                }
              )
              .then((innerRes) => {
                setLoading(false);
                if (innerRes?.data?.status) {
                  toast.success("Registration successful!!");
                }
              })
              .catch(() => {
                setLoading(false);
                toast.error("Failed to register!");
              });
          } else {
            setLoading(false);
            toast.error(res?.data?.message || "Failed to register.");
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error("Failed to register!");
        });
    }
  };

  const handleTriggerClick = () => {
    axiosAuth
      .post(API_ENDPOINTS.FB_EVENT_TRIGGER, {
        email: fbEmail,
        contactNo: fbContact,
      })
      .then(() => {
        toast.success("Successfully triggered fb event!!");
        setFbEmail(null);
        setFbContact(null);
      })
      .catch(() => {
        toast.error("Failed to trigger fb event!!");
      });
  };

  const sendNotification = () => {
    if (notificationTitle && notificationBody) {
      axiosAuth
        .post(API_ENDPOINTS.BROADCAST_NOTIFICATION, {
          notification: {
            title: notificationTitle,
            body: notificationBody,
          },
        })
        .then(() => {
          toast.success("Notification sent to all users!!");
          setNotificationTitle("");
          setNotificationBody("");
        })
        .catch(() => {
          toast.error("Failed to send notification!!");
        });
    }
  };

  const assignCourses = () => {
    const purchasedCourseDetails = [];

    if (course1) {
      purchasedCourseDetails.push({
        amount: COURSE_PLAN_DETAILS.course_amount,
        courseId: COURSE_PLAN_DETAILS._id,
        startDate: moment().format("YYYY/MM/DD"),
        isRefundPeriodOver: false,
        course_name: COURSE_PLAN_DETAILS.course_name,
        slug: COURSE_PLAN_DETAILS.slug,
        txnId: "NA",
        endDate: moment().add(12, "months"),
        userType: "course",
      });
    }

    if (course2) {
      purchasedCourseDetails.push({
        amount: FREE_COURSE.course_amount,
        courseId: FREE_COURSE._id,
        startDate: moment().format("YYYY/MM/DD"),
        isRefundPeriodOver: false,
        course_name: FREE_COURSE.course_name,
        slug: FREE_COURSE.slug,
        txnId: "NA",
        endDate: moment().add(12, "months"),
        userType: "course",
      });
    }

    toggleAssignLoading(true);
    axiosAuth
      .post(`${API_ENDPOINTS.ASSIGN_COURSE}/${email}`, purchasedCourseDetails)
      .then((res) => {
        if (res?.data?.status) {
          toast.success("Course Assigned successful");
          setEmailAssign("");
          setCourse1(false);
          setCourse2(false);
          toggleAssignLoading(false);
        }
      })
      .catch(() => {
        toast.success("Failed to assign course!!");
        toggleAssignLoading(false);
      });
  };

  return (
    <>
      <Container style={{ marginTop: "25px" }}>
        <Row>
          <h3>Users Analytics</h3>
        </Row>
        <div className="analytics-data-container">
          <AnalyticsBox
            color="green"
            count={analyticsData?.totalCount || 0}
            text="Total Users"
          />
          <AnalyticsBox
            color="lightBlue"
            count={analyticsData?.totalCount || 0}
            text="Total Normal Users"
          />
          <AnalyticsBox
            color="black"
            count={analyticsData?.adminUserCount || 0}
            text="Total Admin Users"
          />
          <AnalyticsBox
            color="orange"
            count={analyticsData?.normalUserCount || 0}
            text="Total Active Users"
          />
          <AnalyticsBox
            color="red"
            count={analyticsData?.inactiveUserCount || 0}
            text="Total Inactive Users"
          />
        </div>
      </Container>

      <Container style={{ marginTop: "25px" }}>
        <Row>
          <h3>Sync Wordpress Data</h3>
        </Row>
        <Row>
          <Col xs={24} sm={4} md={4} lg={4}>
            <Button
              loading={coursesLoading}
              disabled={coursesLoading}
              type="primary"
              onClick={handleSyncCourses}
            >
              Sync Courses
            </Button>
          </Col>
          <Col xs={12} sm={4} md={4} lg={4}>
            <Select
              style={{ width: "100%" }}
              onChange={(value) => setSelectedCourse(value)}
            >
              {allCourses.map((x) => (
                <Option key={x.slug} value={x.slug}>
                  {x.course_name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={12} sm={4} md={4} lg={4}>
            <Button
              loading={lessonSyncing}
              disabled={!selectedCourse}
              type="primary"
              onClick={handleLessonCourses}
            >
              Sync Lesson
            </Button>
          </Col>
        </Row>
      </Container>

      <Container style={{ marginTop: "25px" }}>
        <Row>
          <h3>Coupon Code Config</h3>
        </Row>
        <Row>
          <Col xs={24} sm={6} md={6} lg={6}>
            Discount:{" "}
            <InputNumber
              onChange={(val) => setDiscount(val)}
              value={discount}
            />
          </Col>
          <Col xs={24} sm={6} md={6} lg={6}>
            Validity:{" "}
            <InputNumber
              onChange={(val) => setValidity(val)}
              value={validity}
            />
          </Col>
          <Col xs={12} sm={4} md={4} lg={4}>
            <Button
              loading={savingCouponConfig}
              disabled={!discount || !validity}
              type="primary"
              onClick={handleCouponConfigSave}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Container>

      <Container style={{ marginTop: "25px" }}>
        <Row>
          <h3>Rating Config</h3>
        </Row>
        <Row>
          <Col xs={24} sm={6} md={6} lg={6}>
            <Select
              style={{ width: "100%" }}
              onChange={(value) => {
                setRatingSelectedCourse(value);
                getRatings(value);
              }}
              value={ratingSelectedCourse}
            >
              {allCourses.map((x) => (
                <Option key={x.slug} value={x.slug}>
                  {x.course_name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={6} md={6} lg={6}>
            Rating:{" "}
            <InputNumber
              onChange={(val) => setUsersRating(val)}
              value={ratings}
            />
          </Col>
          <Col xs={24} sm={6} md={6} lg={6}>
            Users:{" "}
            <InputNumber
              onChange={(val) => setUsersCount(val)}
              value={usersCount}
            />
          </Col>
          <Col xs={12} sm={4} md={4} lg={4}>
            <Button
              loading={savingRatingConfig}
              disabled={!ratings || !usersCount}
              type="primary"
              onClick={handleRatingConfigSave}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Container>

      <Container className="mt-25" style={{ marginBottom: "50px" }}>
        <Row style={{ marginTop: "35px" }}>
          <h3>Admin Pages Links</h3>
        </Row>
        <Row>
          <Col xs={8} sm={4} md={4} lg={4}>
            <Button type="primary" onClick={() => openPage("/all-users")}>
              All Users
            </Button>
          </Col>
          <Col xs={8} sm={4} md={4} lg={4}>
            <Button type="primary" onClick={() => openPage("/all-ratings")}>
              All Ratings
            </Button>
          </Col>
          <Col xs={24} sm={4} md={4} lg={4}>
            <Button type="primary" onClick={() => openPage("/review-qa")}>
              All Pending QAs ({pendingQA})
            </Button>
          </Col>
          <Col xs={8} sm={4} md={4} lg={4}>
            <Button
              type="primary"
              onClick={() => openPage("/all-live-qa-users")}
            >
              All Live QA Subscribers
            </Button>
          </Col>
        </Row>
      </Container>

      <Container className="mt-25" style={{ marginBottom: "50px" }}>
        <Row style={{ marginTop: "35px" }}>
          <h3>Trigger FB analytics</h3>
        </Row>
        <Row style={{ marginTop: "35px" }}>
          <Col span={24}>
            <p>Email</p>
            <Input
              style={{ width: "200px", marginBottom: "10px" }}
              value={fbEmail}
              onChange={(e) => setFbEmail(e.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Contact No</p>
            <InputNumber
              style={{ width: "200px", marginBottom: "10px" }}
              value={fbContact}
              onChange={(e) => setFbContact(e)}
            />
          </Col>
        </Row>
        <Row>
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            onClick={handleTriggerClick}
          >
            Trigger
          </Button>
        </Row>
      </Container>

      <Container className="mt-25" style={{ marginBottom: "50px" }}>
        <Row style={{ marginTop: "35px" }}>
          <h3>Create User</h3>
        </Row>
        <Row style={{ marginTop: "35px" }}>
          <Col span={24}>
            <p>Name</p>
            <Input
              style={{ width: "200px", marginBottom: "10px" }}
              value={username}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Email</p>
            <Input
              style={{ width: "200px", marginBottom: "10px" }}
              value={useremail}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Password</p>
            <Input
              style={{ width: "200px", marginBottom: "10px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Contact</p>
            <InputNumber
              style={{ width: "200px", marginBottom: "10px" }}
              value={contactNo}
              onChange={(value) => setContact(value)}
            />
          </Col>
          <Col span={24}>
            <p>Txn ID</p>
            <Input
              style={{ width: "200px", marginBottom: "10px" }}
              value={txnId}
              onChange={(e) => setTxnId(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            onClick={handleCreateClick}
          >
            Create
          </Button>
        </Row>
      </Container>

      <Container className="mt-25" style={{ marginBottom: "50px" }}>
        <Row style={{ marginTop: "35px" }}>
          <h3>Assign Course</h3>
        </Row>
        <Row style={{ marginTop: "35px" }}>
          <Input
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmailAssign(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: "35px" }}>
          <Checkbox onChange={() => setCourse1(!course1)}>
            Profitable Trading Masterclass
          </Checkbox>
        </Row>
        <Row style={{ marginTop: "35px" }}>
          <Checkbox onChange={() => setCourse2(!course2)}>
            Trading Psychology Masterclass
          </Checkbox>
        </Row>
        <Spacer />
        <Row>
          <Button
            loading={assignLoading}
            disabled={assignLoading}
            type="primary"
            onClick={assignCourses}
          >
            Assign
          </Button>
        </Row>
      </Container>

      <Container className="mt-25" style={{ marginBottom: "50px" }}>
        <Row style={{ marginTop: "35px" }}>
          <h3>Send Notification</h3>
        </Row>
        <Row style={{ marginTop: "35px" }}>
          <Col span={24}>
            <p>Title</p>
            <Input
              style={{ width: "200px", marginBottom: "10px" }}
              value={notificationTitle}
              onChange={(e) => setNotificationTitle(e.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Body</p>
            <TextArea
              style={{ width: "200px", marginBottom: "10px" }}
              value={notificationBody}
              onChange={(e) => setNotificationBody(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            onClick={sendNotification}
          >
            Send
          </Button>
        </Row>
      </Container>
    </>
  );
};

export default AdminDashboard;
