export const API_ENDPOINTS = {
  LOGIN: "fitness/auth/login",
  GET_USER_PROFILE: "fitness/user/profile",
  GET_CART: "fitness/cart",
  USER_EXIST: 'fitness/user/isUserExists',
  SIGN_UP: 'fitness/auth/signup',
  PURCHASE_COURSE: 'fitness/courses/purchaseCourse',
  GET_ALL_UNAUTHENTICATED_COURSES: 'fitness/courses/allUnAuthCourses',
  ALL_AUTHENTICATED_COURSES: 'fitness/courses/allAuthCourses',
  ASSIGN_COURSE: 'fitness/courses/assign',
  ALL_USERS: 'fitness/user/all',
  CHANGE_USER_STATUS: 'fitness/user/changeUserStatus',
  LESSONS_BY_SLUG: 'fitness/lessons/getLessonsByCourse',
  SYNC_COURSES: 'fitness/courses/syncCourses',
  SYNC_LESSONS_BY_COURSE: 'fitness/lessons/syncLessons',
  UPDATE_WATCH_TIME: 'fitness/courses/addWatchTime',
  MODIFY_REFUND_PERIOD: 'fitness/user/makeRefundablePeriodOver',
  GET_RESOURCES_BY_LESSON_ID: 'fitness/resources/byId',
  ADD_RESOURCE_FOR_LESSON: 'fitness/resources/create',
  DELETE_RESOURCE_FOR_LESSON: 'fitness/resources/delete',
  CREATE_QA_FOR_LESSON: 'fitness/comments/create',
  GET_APPROVED_QA_BY_LESSON_ID: 'fitness/comments/getApprovedComments',
  DELETE_QA_BY_LESSON_ID: 'fitness/comments/delete',
  ADD_REPLY_TO_LESSON: 'fitness/comments/reply/create',
  DELETE_REPLY_UNDER_QA: 'fitness/comments/reply/delete',
  GET_ALL_PENDING_QAS: 'fitness/comments/getPendingComments',
  CHANGE_QA_STATUS: 'fitness/comments/changeCommentStatus',
  // ADD_TO_CART: 'fitness/cart/add',
  // REMOVE_FROM_CART: 'fitness/cart/remove',
  MAKE_REFUND: 'fitness/user/makeRefund',
  USER_UPDATE: 'fitness/user/update',
  CHANGE_PASSWORD: 'fitness/user/changePassword',
  FORGOT_PASSWORD: 'fitness/user/forgetPassword',
  RESET_PASSWORD: 'fitness/user/resetPassword',
  LOGOUT: 'fitness/auth/logout',
  CHANGE_BAN_STATUS: 'fitness/user/changeUserBannedStatus',
  GENERATE_COUPON_CODE: 'fitness/coupon/generateCode',
  GET_COUPON_CONFIG: 'fitness/coupon/config',
  CREATE_UPDATE_COUPON_CONFIG: 'fitness/coupon/config/createAndUpdate',
  GET_COURSE_BY_SLUG: 'fitness/courses',
  // NEWSLETTER_SUBSCRIBE: 'newsletter/subscribe',
  // LIVE_QA_ENROLL: 'qa/enroll',
  // ALL_LIVE_QA_SUBS: 'qa-enroll/all',
  MARK_AS_READ: '/fitness/notification/markAsRead',
  GET_ALL_NOTIFICATION: '/fitness/notification/all',
  BROADCAST_NOTIFICATION: '/fitness/notification/broadcastNotification',
  SUBMIT_RATING: '/fitness/rating/create',
  ALL_RATINGS: '/fitness/rating/all',
  GET_RATING_CONFIG: '/fitness/adminRating',
  CREATE_UPDATE_RATINGS_CONFIG: '/fitness/adminRating/createAndUpdate',
  PAYMENT_STATUS: 'payment/status',
  PAYMENT_STATUS_WEBINAR: 'payment/status/webinar',
  ADMIN_ANALYTICS: '/fitness/analytics',
  FB_EVENT_TRIGGER: '/fitness/user/fb-event',
  // WEBINAR_SIGNUP: '/webinar/register',
  // WEBINAR_CONFIG: '/webinar/config',
  VERIFY_COUPON: 'fitness/cart/coupon',
};
