import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import HeadingUnderline from "../../common/components/HeadingUnderline";

const Spacer = styled.div`
  height: 20px;
`;

const ShippingDelivery = () => (
  <Container>
    <Spacer />
    <HeadingUnderline title="Shipping & Delivery" />
    <h5>
      <strong>NO PHYSICAL SHIPPING</strong>
    </h5>
    <p>
      Aditya Fitness offers digital products and online coaching services. As a
      result, no physical goods are shipped.
    </p>
    <Spacer />
    <h5>
      <strong>SERVICE DELIVERY</strong>
    </h5>
    <p>
      Upon successful payment, customers receive access to their purchased
      programs via email or through our website dashboard. In case of any
      delays, customers can contact us at 6291367906.
    </p>
    <Spacer />
    <div style={{ height: "280px" }} />
  </Container>
);

export default ShippingDelivery;
