import moment from "moment";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { axiosAuth } from "../api";
import { API_ENDPOINTS } from "../api/apiEndpoints";

export const getEnvValue = (name) => {
  const value = process.env[`REACT_APP_${name}`];
  return value || "";
};

export const isUserLoggedIn = () => {
  const token = localStorage.getItem("authToken");
  return !!token;
};

export const makeURLPattern = (str) => str.replace(/\s+/g, "-").toLowerCase();

export const numberWithCommas = (x) => {
  x = x.toString();
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
  return x;
};

export const capitalizeText = (text = "") =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const checkIsLocalhost = () =>
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname === "";

export const triggerGAEvent = (data) => {
  ReactGA.event(data);
};

export const handleBecomeMemberTrackingClick = () => {
  const data = {
    category: "Links",
    action: "Become a member website",
    label: "Clicked on become a member button",
    value: 1,
  };
  triggerGAEvent(data);
};

export const handleVideoPlayTracking = (type) => {
  const data = {
    category: "Video",
    action: `Played video ${type}`,
    label: `Played video ${type}`,
    value: type,
  };
  triggerGAEvent(data);
};

export const getDateTextFromDate = (date = "") => {
  const m = moment(date);
  const differenceDays = moment().diff(m, "days");
  if (differenceDays == 0) {
    return "Today";
  }
  if (differenceDays == 1) {
    return "Yesterday";
  }
  if (differenceDays == 2) {
    return "2d ago";
  }
  if (differenceDays == 3) {
    return "3d ago";
  }
  if (differenceDays == 4) {
    return "4d ago";
  }
  if (differenceDays == 5) {
    return "5d ago";
  }
  if (differenceDays == 6) {
    return "6d ago";
  }
  if (differenceDays == 7) {
    return "7d ago";
  }
  return m.format("DD MMM YYYY");
};

export const getRandomNumberInRange = (min, max) =>
  Math.floor(Math.random() * (max - min) + min);

export const logoutUserHandler = async (logoutUser, navigate) => {
  axiosAuth.get(API_ENDPOINTS.LOGOUT);
  logoutUser();
  setTimeout(() => {
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    navigate("/");
  }, 150);
};

export const checkImageSize = (file) => {
  const size = file.size / (1024 * 1024);
  if (size >= 2) {
    toast("Image size should be less than 2 MB.", {
      type: toast.TYPE.ERROR,
    });
    return false;
  }
  return true;
};

export const setFileListBySize = (fileList, setFileList) => {
  fileList.forEach((item, index) => {
    const size = item.size / (1024 * 1024);
    if (size >= 2) {
      fileList.splice(index, 1);
    }
  });
  setFileList(fileList);
};

export const isUserDetailsUpdate = (oldData, newData) => {
  const oldCoupon = JSON.stringify(oldData.coupon);
  const newCoupon = JSON.stringify(newData.coupon);
  const oldPurchasedCourses = JSON.stringify(oldData.purchasedCourses);
  const newPurchasedCourses = JSON.stringify(newData.purchasedCourses);
  const oldRatings = JSON.stringify(oldData.ratings ?? []);
  const newRatings = JSON.stringify(newData.ratings ?? []);

  if (
    oldData?.active === newData?.active &&
    oldData?.email === newData?.email &&
    oldData?.name === newData?.name &&
    oldData?.profileImg === newData?.profileImg &&
    oldData?.userType === newData?.userType &&
    oldData?.isBanned === newData?.isBanned &&
    oldData?._id === newData?._id &&
    oldCoupon === newCoupon &&
    oldPurchasedCourses === newPurchasedCourses &&
    oldRatings === newRatings &&
    oldData?.createdAt === newData?.createdAt
  ) {
    return true;
  }
  return false;
};

export const courseAddedToCart = (value, useremail) => {
  const emailAddress = useremail?.split("@");
  const data = {
    category: `${useremail ? "Subscriber " : ""}Cart`,
    action: `${useremail ? "Subscriber " : ""}Added course to cart`,
    label: `${
      useremail ? `${emailAddress[0]} ${emailAddress[1]}: ` : ""
    }Added ${value} course to cart`,
    value,
  };
  triggerGAEvent(data);
};

export const manipulateTime = (date) => {
  if (date) {
    const dateString = date.split("T")[0].split("-");
    const newDate = moment();
    newDate.set({
      date: dateString[2],
      month: Number(dateString[1]) - 1,
      year: dateString[0],
      hour: 23,
      minute: 59,
      second: 59,
    });
    return newDate;
  }
  return moment(date);
};

export const getIsCouponExpiryTime = (expiry) => {
  const dateFrags = expiry.split("T");
  const timeFrags = dateFrags[1]?.split(":");
  const expiryDate = new Date(dateFrags[0]);
  expiryDate.setHours(
    timeFrags[0]
      ? timeFrags[0] < 10
        ? Number(timeFrags[0].toString().charAt(1))
        : timeFrags[0]
      : 22
  );

  expiryDate.setMinutes(
    timeFrags[1]
      ? timeFrags[1] < 10
        ? Number(timeFrags[1].toString().charAt(1))
        : 0
      : 0
  );
  expiryDate.setSeconds(0);
  const currentTime = new Date().getTime();
  const expiryTime = expiryDate.getTime();
  return currentTime > expiryTime;
};
