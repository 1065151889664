import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MemoizedLessonItem } from './LessonItem';

const { Panel } = Collapse;

const LessonsList = ({ allLessons, toggleMenu }) => {
  const currentLesson = useSelector((state) => state.lessons.currentLesson);
  const [activeSectionKey, setActiveSessionKey] = useState(null);
  const [isScroll, setIsScroll] = useState(false);
  const allLessonsList = R.pipe(
    R.map(R.prop('lessons')),
    R.flatten,
  )(allLessons);
  const refs = allLessonsList.reduce((acc, value) => {
    acc[value._id] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    setActiveSessionKey(currentLesson?.section?._id);
    setIsScroll(true);
  }, [currentLesson]);

  useEffect(() => {
    if (isScroll && refs && refs[currentLesson?._id]?.current) {
      refs[currentLesson?._id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setIsScroll(false);
    }
  }, [refs, isScroll]);

  return (
    <div
      className="testing"
      style={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 7%)' }}
    >
      <SectionText>Sections</SectionText>
      <StyledCollapse
        expandIconPosition="right"
        activeKey={activeSectionKey}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            style={{ fontSize: '20px' }}
            rotate={isActive ? 90 : 0}
          />
        )}
        ghost
        onChange={(key) => setActiveSessionKey(key)}
      >
        {allLessons.map((x, sectionIndex) => (
          <Panel
            header={(
              <div
                style={{
                  backgroundColor: '#F7F9FA',
                  minHeight: '66px',
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'uppercase',
                }}
              >
                {x.post_title}
              </div>
            )}
            key={x._id}
          >
            {x.lessons.map((item, index) => (
              <div key={x._id} ref={refs[item._id]}>
                <MemoizedLessonItem
                  ref={refs[item._id]}
                  item={item}
                  index={index}
                  sectionIndex={sectionIndex}
                  toggleMenu={toggleMenu}
                />
              </div>
            ))}
          </Panel>
        ))}
      </StyledCollapse>
    </div>
  );
};

export default React.memo(LessonsList);

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    background-color: #f7f9fa;
    border-top: 1px solid #d1d7dc;
  }
  .ant-collapse-header {
    font-weight: 700;
    line-height: 1.2;
    font-size: 14px;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

const SectionText = styled.div`
  font-size: 20px;
  font-weight: 700;
  padding-left: 15px;
  background: #1A9C5B;
  min-height: 45px;
  display: flex;
  align-items: center;
`;
