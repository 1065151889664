import { DeleteFilled, LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { axiosAuth } from '../../api';
import { API_ENDPOINTS } from '../../api/apiEndpoints';
import { CenteredDiv, Spacer } from '../../common/components';
import './LessonComments.scss';

const LessonResources = ({
  activeKey,
  setShowResources,
  setResourcesCount,
}) => {
  const params = useParams();
  const currentLesson = useSelector((state) => state.lessons.currentLesson);
  const user = useSelector((state) => state.common.user);
  const [editorData, setEditorData] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [lessonLoading, setLessonLoading] = useState(false);
  const [allResources, setResources] = useState([]);

  const getResourcesforLesson = () => {
    setLessonLoading(true);
    if (currentLesson?._id) {
      axiosAuth
        .get(
          `${API_ENDPOINTS.GET_RESOURCES_BY_LESSON_ID}/${currentLesson?._id}`,
        )
        .then((res) => {
          setLessonLoading(false);
          if (res?.data?.status && res?.data?.data?.length) {
            setResourcesCount(res?.data?.data?.length);
            setResources(res?.data?.data);
            setShowResources(true);
          } else {
            setResources([]);
            setShowResources(false);
            setResourcesCount(0);
          }
        });
    }
  };

  useEffect(() => {
    setEditorData(EditorState.createEmpty());
  }, [activeKey]);

  const handleResourceCreate = () => {
    setLoading(true);
    axiosAuth
      .post(API_ENDPOINTS.ADD_RESOURCE_FOR_LESSON, {
        content: draftToHtml(
          convertToRaw(editorData.getCurrentContent()),
        ).toString(),
        images: [],
        course_slug: params.slug,
        lesson_id: currentLesson?._id,
      })
      .then((res) => {
        if (res?.data?.status) {
          setEditorData(EditorState.createEmpty());
          setLoading(false);
          getResourcesforLesson();
        }
      });
  };

  useEffect(() => {
    getResourcesforLesson();
  }, [currentLesson]);

  const handleResourceDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this resource?')) {
      axiosAuth
        .delete(`${API_ENDPOINTS.DELETE_RESOURCE_FOR_LESSON}/${id}`)
        .then((res) => {
          if (res?.data?.status) {
            getResourcesforLesson();
          }
        });
    }
  };

  return (
    <div>
      <Row>
        {user?.userType === 'admin' && (
          <Col span={24}>
            <Editor
              editorState={editorData}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(editorState) => {
                setEditorData(editorState);
              }}
            />
          </Col>
        )}
        {user?.userType === 'admin' && (
          <StyledColFlex span={24}>
            <Button
              loading={loading}
              disabled={loading}
              onClick={handleResourceCreate}
              type="primary"
            >
              Create
            </Button>
          </StyledColFlex>
        )}
      </Row>
      {lessonLoading ? (
        <CenteredDiv>
          <LoadingOutlined style={{ fontSize: 30, color: '#1A9C5B' }} spin />
        </CenteredDiv>
      ) : (
        <Row>
          {allResources.map((item) => {
            const description = (item?.content ?? '').replaceAll('\r\n\r\n\r\n', '<br/><br/>').replaceAll('\r\n\r\n', '<br/><br/>').replaceAll('&nbsp;', '');
            return (
              <StyledCol span={24} key={item?._id}>
                <div style={{ width: '100%' }}>
                  {parse(description, {
                    transform: function transform(node) {
                      if (node.type === 'tag' && node.name === 'a') {
                        const text = node?.children[0]?.data ?? node?.children[0]?.children[0]?.data ?? 'Click here to see the resource';
                        return (
                          <a href={node?.attribs?.href ?? ''} target="_blank" rel="noreferrer">
                            {text}
                          </a>
                        );
                      }
                    },
                  })}
                </div>
                {user?.userType === 'admin' && (
                <StyledDeleteIcon
                  onClick={() => handleResourceDelete(item?._id)}
                />
                )}
              </StyledCol>
            );
          })}
        </Row>
      )}
      <Spacer />
      <Spacer />
    </div>
  );
};

export default LessonResources;

export const StyledDeleteIcon = styled(DeleteFilled)`
  position: absolute;
  right: 15px;
  font-size: 20px;
  color: red;
  cursor: pointer;
`;

export const StyledColFlex = styled(Col)`
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const StyledCol = styled(Col)`
  position: relative;
  min-height: 50px;
  display: flex;
  align-items: center;
  border: 1px solid #d1d7dc;
  border-radius: 5px;
  margin-bottom: 10px;
  background: white;
  padding: 0 10px;
`;
