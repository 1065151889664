import { LockFilled, PlayCircleFilled } from '@ant-design/icons';
import { Progress, Tag } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { removeNewFromLesson, setCurrentVideo } from '../../actions/lessons';
import { axiosAuth } from '../../api';
import { API_ENDPOINTS } from '../../api/apiEndpoints';

const LessonItem = ({
  item, index, sectionIndex, toggleMenu,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.common.user);
  const currentLesson = useSelector((state) => state.lessons.currentLesson);
  const userCourseData = user?.purchasedCourses?.length
    ? user?.purchasedCourses.find((x) => x.slug === params.slug)
    : null;
  const handleLessonClick = (lesson) => {
    if (toggleMenu) {
      toggleMenu(false);
    }
    if (currentLesson?._id !== lesson?._id) {
      axiosAuth.post(API_ENDPOINTS.UPDATE_WATCH_TIME, {
        courseSlug: params.slug,
        isCompleted: lesson?.isCompleted === true,
        lessonId: lesson?._id,
      });
      dispatch(setCurrentVideo({ ...lesson, isNewLesson: false }));
      dispatch(
        removeNewFromLesson(sectionIndex, index, {
          ...lesson,
          isNewLesson: false,
        }),
      );
    }
  };
  const isActive = currentLesson?._id === item?._id;
  const totalProgress = Math.ceil(
    ((item?.watchTime ?? 0) / item?.duration_sec) * 100,
  );

  return (
    <LessonItemParent
      isActive={isActive}
      onClick={() => handleLessonClick(item)}
    >
      <LectureDetail>
        <LectureHeading>
          {item.post_title}
          {item.is_locked === 'yes' && userCourseData?.isRefundPeriodOver === false && (
            <LockFilled style={{ fontSize: '16px' }} />
          )}
        </LectureHeading>
        <LectureDuration>
          <LectureDurationField>
            <PlayCircleFilled />
            &nbsp;&nbsp;
            {item.duration}
          </LectureDurationField>
          {item?.isNewLesson && (
            <Tag style={{ marginRight: 0 }} color="#87d068">
              New
            </Tag>
          )}
          {item?.isCompleted && (
            <Tag style={{ marginRight: 0 }} color="#87d068">
              Completed
            </Tag>
          )}
        </LectureDuration>
        <ProgressContainer>
          <Progress
            strokeColor="#1A9C5B"
            percent={totalProgress}
            status={isActive ? 'active' : ''}
          />
        </ProgressContainer>
      </LectureDetail>
    </LessonItemParent>
  );
};

export const MemoizedLessonItem = React.memo(LessonItem);

const LessonItemParent = styled.div`
  background-color: ${({ isActive }) => (isActive ? '#d1d7dc' : 'white')};
  border-top: 1px solid black;
  padding: 12px 16px;
  &:hover {
    background-color: #d1d7dc;
    cursor: pointer;
  }
`;

const LectureHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LectureDetail = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const LectureDuration = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ProgressContainer = styled.div`
  width: 100%;
`;

const LectureDurationField = styled.div`
  display: flex;
  align-items: center;
`;
