import { Outlet, useLoaderData, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Footer from "./common/components/Footer";
import Header from "./common/components/Header/Header";
import { BLOCKED_PATHNAME } from "./constant";
import { theme } from "./utils/theme";

const RootLayout = (props) => {
  console.log({ props })
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const headerParam = params.get("header");
  const courseData = useLoaderData()
  console.log({ courseData })
  return (
    <ThemeProvider theme={theme}>
      {!BLOCKED_PATHNAME.includes(location?.pathname) && !headerParam && (
        <Header />
      )}
      <Outlet context={courseData} />
      <Footer />
    </ThemeProvider>
  );
};

export default RootLayout;
