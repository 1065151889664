import { LazyMotion } from "framer-motion";

const loadFeatures = () =>
  import("./framer-motion-features").then((res) => res.default);

const FramerMotionProvider = ({ children }) => {
  return (
    <LazyMotion strict features={loadFeatures}>
      {children}
    </LazyMotion>
  );
};

export default FramerMotionProvider;