// AuthRoute.jsx
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLoaderData } from "react-router-dom";
import { SET_USER_DATA } from "./actions/common";
import Loader from "./Loader";

export const isUserDetailsUpdate = (oldData, newData) => {
  const oldCoupon = JSON.stringify(oldData.coupon);
  const newCoupon = JSON.stringify(newData.coupon);
  const oldPurchasedCourses = JSON.stringify(oldData.purchasedCourses);
  const newPurchasedCourses = JSON.stringify(newData.purchasedCourses);
  const oldRatings = JSON.stringify(oldData.ratings ?? []);
  const newRatings = JSON.stringify(newData.ratings ?? []);

  if (
    oldData?.active === newData?.active &&
    oldData?.email === newData?.email &&
    oldData?.name === newData?.name &&
    oldData?.profileImg === newData?.profileImg &&
    oldData?.userType === newData?.userType &&
    oldData?.isBanned === newData?.isBanned &&
    oldData?._id === newData?._id &&
    oldCoupon === newCoupon &&
    oldPurchasedCourses === newPurchasedCourses &&
    oldRatings === newRatings &&
    oldData?.createdAt === newData?.createdAt
  ) {
    return true;
  }
  return false;
};

const AuthRoute = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const dispatch = useDispatch();
  const user = useLoaderData();
  useEffect(() => {
    const userData = localStorage.getItem("user");
    const token = localStorage.getItem("authToken") || "";

    // For setting the updated user data
    if (userData) {
      const user = JSON.parse(userData);
      dispatch({
        type: SET_USER_DATA,
        payload: {
          user: userData
            ? {
                ...user,
              }
            : null,
          token,
        },
      });

      if (user?._id) {
        setIsAuthenticated(true);
        const result = isUserDetailsUpdate(user, user);
        if (!result) {
          localStorage.setItem("user", JSON.stringify(user));
          dispatch({
            type: SET_USER_DATA,
            payload: { user: user ?? null },
          });
        }
      } else {
        setIsAuthenticated(false);
        localStorage.removeItem("user");
        localStorage.removeItem("authToken");
      }
    } else {
      setIsAuthenticated(false);
      localStorage.removeItem("user");
      localStorage.removeItem("authToken");
    }
  }, [user, dispatch]);

  if (isAuthenticated === null) {
    return <Loader/>;
  }
  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default AuthRoute;
